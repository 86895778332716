<template>
  <header
    class="block md:hidden bg-[#f7f7f7] sticky top-0 z-10 font-helvetica"
    :class="isScrolled ? 'py-2 px-3' : 'py-3 px-5'"
  >
    <div class="flex justify-center w-full gap-3">
      <router-link
        to="/yellow-page/contact"
        class="bg-[#09396C] text-white text-sm transition-all duration-300"
        :class="isScrolled ? 'p-1' : 'p-2'"
      >
        {{ lang === "mn" ? "Холбоо барих" : "Contact" }}
      </router-link>
      <router-link
        to="/yellow-page/register"
        class="bg-[#CC4628] text-white text-sm transition-all duration-300"
        :class="isScrolled ? 'p-1' : 'p-2'"
      >
        {{ lang === "mn" ? "Мэдээлэл нэмэх" : "Send request" }}
      </router-link>
    </div>

    <div class="flex justify-between items-center gap-3 mt-3">
      <button
        @click="toggleLanguage()"
        class="transition-all duration-300 hover:text-[#09396C]"
        :class="isScrolled ? 'text-base' : 'text-lg'"
      >
        <font-awesome-icon icon="fa-solid fa-globe" />
      </button>
      <router-link to="/yellow-page">
        <img
          :src="
            lang === 'mn'
              ? require('@/assets/images/miningMongolia/header_logo_mn.png')
              : require('@/assets/images/miningMongolia/header_logo_en.png')
          "
          alt="logo"
          class="w-[10rem] min-[425px]:w-[12rem] object-fit"
        />
      </router-link>
    </div>
  </header>

  <header
    class="hidden md:flex justify-center sticky top-0 bg-[#f7f7f7] py-3 font-helvetica z-10"
  >
    <div class="flex justify-between items-center w-full min-[1200px]:w-4/5">
      <router-link to="/yellow-page" class="w-1/5">
        <img
          :src="
            lang === 'mn'
              ? require('@/assets/images/miningMongolia/header_logo_mn.png')
              : require('@/assets/images/miningMongolia/header_logo_en.png')
          "
          alt="logo"
          class="object-fit"
        />
      </router-link>

      <div class="w-3/4">
        <div class="flex justify-end gap-4">
          <button
            @click="toggleLanguage()"
            class="transition-all duration-300 hover:text-[#09396C]"
            :class="isScrolled ? 'text-base' : 'text-lg'"
          >
            <font-awesome-icon icon="fa-solid fa-globe" />
          </button>
          <router-link
            to="/yellow-page/contact"
            class="bg-[#09396C] text-white transition-all duration-300 text-sm rounded"
            :class="isScrolled ? 'p-1.5' : 'px-2 py-3'"
          >
            {{ lang === "mn" ? "Холбоо барих" : "Contact" }}
          </router-link>
          <router-link
            to="/yellow-page/register"
            class="bg-[#CC4628] text-white transition-all duration-300 text-sm rounded"
            :class="isScrolled ? 'p-1.5' : 'px-2 py-3'"
          >
            {{ lang === "mn" ? "Мэдээлэл нэмэх" : "Send request" }}
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "YellowNavbar",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const toggleLanguage = () => {
      store.dispatch("toggleLanguage");
    };

    const isScrolled = ref(false);

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 50;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      isScrolled,
      lang,
      toggleLanguage,
    };
  },
};
</script>
