<template>
  <admin-navbar>
    <h1 class="font-bold text-lg">Жагсаалт</h1>

    <div class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">Сайтад байршсан мэдээллийн жагсаалт</h6>
        <router-link
          to="/admin/yellow/add"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Шинээр нэмэх
        </router-link>
      </div>

      <div class="flex justify-between items-center p-3 text-xs">
        <div class="flex items-center gap-3">
          <label for="selectedType">Хэрэглэгчийн төрөл:</label>
          <select
            name="selectedType"
            id="selectedType"
            class="p-1 border rounded"
            v-model="selectedType"
          >
            <option value="" disabled>Хайлтын төрөл</option>
            <option value="1">Байгууллага</option>
            <option value="2">Зөвлөх инженер</option>
          </select>
        </div>

        <div class="space-x-3">
          <span>Хуудас бүрт:</span>
          <select
            name="dataPerPage"
            id="dataPerPage"
            class="border rounded p-1 text-blue-500"
            v-model="dataPerPage"
          >
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <span>Нийт хэрэглэгчид тоо: {{ totalRequestCount }} </span>

        <div class="flex items-center gap-3">
          <span>Нийт хуудас: {{ totalPages }} </span>
          <PaginationCart
            :totalPages="totalPages"
            :modelValue="currentPage"
            :style="{
              color: 'blue-500',
              height: 7,
              width: 7,
              fontSize: 'xs',
            }"
            @page-changed="onPageChanged"
          />
        </div>
      </div>

      <div
        v-if="requests.length > 0 && !isLoading"
        class="p-3 space-y-5 text-xs"
      >
        <YellowCompanyTable
          v-if="selectedType === 1 || selectedType === '1'"
          :data="requests"
          @fetchData="fetchRequestData"
        />
        <YellowConsultantTable
          v-else-if="selectedType === 2 || selectedType === '2'"
          :data="requests"
          @fetchData="fetchRequestData"
        />
      </div>

      <div
        v-else-if="requests.length === 0 && !isLoading"
        class="flex justify-center items-center h-[50vh]"
      >
        Жагсаалт хоосон байна
      </div>

      <div v-else class="flex justify-center items-center h-[50vh]">
        <SpinLoading />
      </div>
    </div>
  </admin-navbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { onMounted, ref, watch } from "vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import YellowCompanyTable from "@/containers/YellowPage/admin/YellowCompanyTable.vue";
import YellowConsultantTable from "@/containers/YellowPage/admin/YellowConsultantTable.vue";

export default {
  name: "AdminYellowUpdate",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    YellowCompanyTable,
    YellowConsultantTable,
  },
  setup() {
    const isLoading = ref(false);
    const requests = ref([]);
    const selectedType = ref(1);
    const dataPerPage = ref(30);
    const totalRequestCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const fetchRequestData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/yellow-page/get/approved?type=${selectedType.value}&page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        requests.value = res.data.request;
        totalPages.value = res.data.totalPages;
        totalRequestCount.value = res.data.total;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchRequestData();
    });

    watch([selectedType, dataPerPage], () => {
      currentPage.value = 1;
      fetchRequestData();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchRequestData();
    };

    return {
      isLoading,
      requests,
      dataPerPage,
      totalRequestCount,
      totalPages,
      currentPage,
      onPageChanged,
      selectedType,
      fetchRequestData,
    };
  },
};
</script>
