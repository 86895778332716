<template>
  <main class="flex justify-center bg-white min-h-screen p-10 font-helvetica">
    <div class="w-full min-[1200px]:w-5/6">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.1640691233565!2d106.92311487660623!3d47.91386277121976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9692382b1e57f5%3A0x4ac60a62935dde92!2sSKY%20PLAZA!5e0!3m2!1sen!2smn!4v1734489883684!5m2!1sen!2smn"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        class="w-full min-h-24 h-96"
      ></iframe>

      <div
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10 max-sm:divide-y lg:divide-x"
      >
        <div
          v-for="(info, index) in information"
          :key="index"
          class="flex flex-col items-center gap-4 p-3"
        >
          <div
            class="flex justify-center items-center bg-gray-300 h-16 w-16 rounded-xl text-white text-2xl"
          >
            <font-awesome-icon :icon="info.icon" />
          </div>
          <h5>{{ lang === "mn" ? info.type : info.type_en }}</h5>
          <p class="text-[#CC4628] text-sm text-center">
            {{ lang === "mn" ? info.text : info.text_en }}
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "AssociationContact",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const information = ref([
      {
        icon: "phone",
        type: "Утасны дугаар",
        type_en: "Phone Number",
        text: "+976 70110163",
        text_en: "+976 70110163",
      },
      {
        icon: "envelope",
        type: "И-мэйл хаяг",
        type_en: "Email Address",
        text: "info@miningmongolia.mn",
        text_en: "info@miningmongolia.mn",
      },
      {
        icon: "map",
        type: "Хаяг",
        type_en: "Office Location",
        text: "Монгол Улс, Улаанбаатар хот, СБД, 1-р хороо, Элчингийн гудамж, Скай плаза бизнес төв, Д корпус 2 давхар",
        text_en:
          "2nd floor, D section, 'Sky Plaza' Business Center, Embassy Rd, Sukhbaatar district, Ulaanbaatar, Mongolia 14220",
      },
      {
        icon: "clock",
        type: "Ажлын цаг",
        type_en: "Opening Hours",
        text: "Даваа-Баасан / 09:00-18:00",
        text_en: "Monday-Friday / 09:00-18:00",
      },
    ]);

    return { lang, information };
  },
};
</script>
