<template>
  <main class="flex justify-center bg-white min-h-screen font-helvetica">
    <div class="w-full min-[1200px]:w-4/5 py-10">
      <!-- SLIDES -->
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :autoplay="{
          delay: 8000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }"
        :pagination="{ clickable: true }"
        class="mySwiper mb-10"
      >
        <swiper-slide v-for="(slide, index) in images" :key="index">
          <img :src="slide" alt="image" class="w-full rounded" />
        </swiper-slide>
      </swiper>

      <div class="flex w-full gap-10">
        <!-- CATEGORIES -->
        <section class="w-1/4">
          <h4
            class="bg-[#CC4628] text-center uppercase text-white py-3 rounded-t"
          >
            {{ lang === "mn" ? "Ангилал" : "Categories" }}
          </h4>

          <Transition name="fade" class="flex flex-col border rounded-b">
            <div>
              <div
                v-for="(mainCat, index) in categoryNames"
                :key="index"
                class="w-full"
              >
                <button
                  class="bg-[#f7f7f7] p-4 text-start hover:text-[#cc4628] w-full group"
                  :class="
                    mainCategory === mainCat.id
                      ? 'font-bold text-[#09396C]'
                      : 'text-gray-500'
                  "
                  @click="
                    setMainCategory(mainCat.id, mainCat.nameMn, mainCat.nameEn)
                  "
                >
                  <div class="flex items-center justify-between w-full">
                    <div class="flex items-center">
                      <font-awesome-icon
                        v-if="mainCat.id === '01'"
                        icon="fa-solid fa-helmet-safety"
                        class="mr-1.5"
                      />
                      <font-awesome-icon
                        v-if="mainCat.id === '02'"
                        icon="fa-solid fa-boxes-packing"
                        class="mr-1.5"
                      />
                      <font-awesome-icon
                        v-if="mainCat.id === '03'"
                        icon="fa-solid fa-screwdriver-wrench"
                        class="mr-1.5"
                      />
                      <font-awesome-icon
                        v-if="mainCat.id === '04'"
                        icon="fa-solid fa-building-columns"
                        class="mr-1.5"
                      />
                      {{ lang === "mn" ? mainCat.nameMn : mainCat.nameEn }}
                    </div>
                    <div>
                      <font-awesome-icon
                        icon="fa-solid fa-chevron-right"
                        :class="
                          mainCategory === mainCat.id
                            ? 'transition-transform duration-300 rotate-90'
                            : ''
                        "
                      />
                    </div>
                  </div>
                </button>

                <div
                  v-if="mainCategory === mainCat.id"
                  class="flex flex-col gap-1 ml-8 my-5"
                >
                  <button
                    v-for="subCat in subCategories"
                    :key="subCat.id"
                    class="flex gap-2 text-start px-2 hover:text-[#cc4628]"
                    :class="
                      subCategory === subCat.id
                        ? 'font-bold text-[#09396C]'
                        : 'text-gray-500'
                    "
                    @click="
                      setSubCategory(
                        subCat.id,
                        subCat.category_code,
                        subCat.name
                      )
                    "
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-circle"
                      class="text-[0.3rem] pt-2"
                    />
                    {{ subCat.category_code }} - {{ subCat.name }}
                  </button>
                </div>
              </div>
            </div>
          </Transition>
        </section>

        <!-- COMPANIES -->
        <section class="w-3/4">
          <div class="flex justify-between items-center text-sm">
            <div class="flex gap-1 sm:gap-2 text-sm text-[#09396C] italic">
              <h6>{{ mainCategoryName }}</h6>
              <span v-if="subCategory" class="text-gray-500">/</span>
              <h6 v-if="subCategory">{{ subCategoryName }}</h6>
            </div>
            <p class="italic text-gray-500 text-sm">
              Нийт илэрц: {{ totalData }}
            </p>
          </div>

          <!-- search -->
          <div class="flex mt-5 text-sm">
            <div
              class="flex justify-center items-center bg-[#f7f7f7] py-2 px-4 rounded-l"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </div>
            <input
              v-if="subCategory === 95"
              type="text"
              :placeholder="
                subCategory === 95
                  ? lang === 'mn'
                    ? 'Инженерийн нэр'
                    : 'Surname'
                  : lang === 'mn'
                  ? 'Байгууллагын нэр'
                  : 'Search company'
              "
              class="w-[12rem] sm:w-[15rem] border p-2"
              v-model="personName"
              @input="handleSearch()"
            />
            <input
              v-else
              type="text"
              :placeholder="
                subCategory === 95
                  ? lang === 'mn'
                    ? 'Инженерийн нэр'
                    : 'Surname'
                  : lang === 'mn'
                  ? 'Байгууллагын нэр'
                  : 'Search company'
              "
              class="w-[12rem] sm:w-[15rem] border p-2"
              v-model="companyName"
              @input="handleSearch()"
            />
            <button
              @click="emptyName"
              class="bg-[#f7f7f7] py-2 px-4 text-center rounded-r group"
            >
              <font-awesome-icon
                icon="fa-solid fa-xmark"
                class="transition-transform duration-300 group-hover:rotate-90"
              />
            </button>
          </div>

          <div v-if="subCategory === 95" class="mt-10">
            <table
              v-if="filteredPeople.length > 0 && !isLoading"
              class="w-full border rounded-lg divide-y divide-gray-200"
            >
              <thead class="bg-[#09396C] text-white">
                <tr>
                  <th class="px-4 py-2 text-center uppercase">Нэр</th>
                  <th class="px-4 py-2 text-center uppercase">Мэргэжил</th>
                  <th class="px-4 py-2 text-center uppercase">Зөвлөгөө</th>
                  <th class="px-4 py-2 text-center uppercase">Утас</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="person in filteredPeople" :key="person.id">
                  <td class="px-4 py-2 text-sm text-nowrap">
                    {{
                      lang === "mn"
                        ? person.person_info?.translations?.name?.mn === "NULL"
                          ? ""
                          : person.person_info?.translations?.name?.mn
                        : person.person_info?.translations?.name?.en === "NULL"
                        ? ""
                        : person.person_info?.translations?.name?.en
                    }}
                  </td>
                  <td class="px-4 py-2 text-sm">
                    {{
                      lang === "mn"
                        ? person.person_info?.translations?.profession?.mn ===
                          "NULL"
                          ? ""
                          : person.person_info?.translations?.profession?.mn
                        : person.person_info?.translations?.profession?.en ===
                          "NULL"
                        ? ""
                        : person.person_info?.translations?.profession?.en
                    }}
                  </td>
                  <td class="px-4 py-2 text-sm">
                    {{
                      lang === "mn"
                        ? person.person_info?.translations?.advice_range?.mn ===
                          "NULL"
                          ? ""
                          : person.person_info?.translations?.advice_range?.mn
                        : person.person_info?.translations?.advice_range?.en ===
                          "NULL"
                        ? ""
                        : person.person_info?.translations?.advice_range?.en
                    }}
                  </td>
                  <td class="px-4 py-2 text-sm text-center">
                    {{ person.phone_number ? person.phone_number : "" }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              v-else-if="filteredPeople.length === 0 && !isLoading"
              class="col-span-5 text-center mt-5"
            >
              {{ lang === "mn" ? "Илэрц олдсонгүй." : "No companies found." }}
            </div>

            <div v-else class="flex justify-center items-center w-full mt-10">
              <BarLoading />
            </div>
          </div>

          <div v-else>
            <div
              v-if="filteredCompanies.length === 0 && !isLoading"
              class="col-span-5 text-center mt-5"
            >
              {{ lang === "mn" ? "Илэрц олдсонгүй." : "No companies found." }}
            </div>

            <div v-else-if="filteredCompanies.length > 0 && !isLoading">
              <div
                class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-8 w-full mt-10"
              >
                <router-link
                  v-for="company in filteredCompanies"
                  :key="company.company_id"
                  :to="`/yellow-page/${company.company_id}`"
                  class="text-center space-y-3 hover:scale-105"
                >
                  <div
                    v-if="company.company_info?.translations?.logo"
                    class="flex justify-center"
                  >
                    <img
                      :src="company.company_info.translations.logo"
                      alt="logo"
                      class="h-24 object-fit"
                    />
                  </div>

                  <div v-else>
                    <h1
                      class="flex items-center justify-center h-24 uppercase text-gray-300 text-4xl mb-3"
                    >
                      ЛОГО
                    </h1>
                    {{
                      lang === "mn"
                        ? company.company_info?.translations?.name?.mn || ""
                        : company.company_info?.translations?.name?.en || ""
                    }}
                  </div>
                </router-link>
              </div>
            </div>

            <div v-else class="flex justify-center items-center w-full mt-10">
              <BarLoading />
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css/bundle";
import clientYellowInstance from "@/lib/yellowClientInstance";
import { ref, computed, onMounted, watch, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import BarLoading from "@/components/ui/BarLoading.vue";

export default {
  name: "YellowNewCategory",
  components: {
    Swiper,
    SwiperSlide,
    BarLoading,
  },
  setup() {
    const route = useRoute();
    const isRoute = ref(route.path);
    const routeId = ref(route.params.id);

    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const images = ref([
      "https://miningmongolia.mn/static/news/1722391941659.png",
      "https://miningmongolia.mn/static/news/1722391946712.png",
      "https://miningmongolia.mn/static/news/1722391950994.png",
    ]);

    const categoryNames = ref([
      {
        id: "01",
        nameMn: "Уул уурхайн компани",
        nameEn: "Mining Companies",
      },
      {
        id: "02",
        nameMn: "Ханган нийлүүлэгч",
        nameEn: "Supplier Companies",
      },
      {
        id: "03",
        nameMn: "Үйлчилгээ үзүүлэгч",
        nameEn: "Service Providers",
      },
      {
        id: "04",
        nameMn: "Бусад",
        nameEn: "Others",
      },
    ]);

    const isLoading = ref(false);
    const companyName = ref(null);
    const personName = ref(null);
    const mainCategory = ref("01");
    const subCategory = ref("");
    const subCategories = ref([]);
    const companies = ref([]);
    const people = ref([]);
    const totalData = ref(0);
    const mainCategoryName = ref(null);
    const subCategoryName = ref(null);

    const setMainCategory = (id, nameMn, nameEn) => {
      mainCategory.value = id;
      if (lang.value === "mn") {
        mainCategoryName.value = `${id} - ${nameMn}`;
      } else {
        mainCategoryName.value = `${id} - ${nameEn}`;
      }
    };

    const setSubCategory = (id, code, name) => {
      subCategory.value = id;
      subCategoryName.value = `${code} - ${name}`;
    };

    const fetchSubCategories = async () => {
      try {
        const res = await clientYellowInstance.get(
          `/categories?main=${mainCategory.value}&language=${lang.value}`
        );

        if (res.status === 200) {
          subCategories.value = res.data.subCategories;
        }
      } catch (err) {
        return err;
      }
    };

    const fetchCompanies = async () => {
      try {
        isLoading.value = true;

        if (subCategory.value === 95) {
          const res = await clientYellowInstance.get(
            `/people?lang=${lang.value}`
          );
          if (res.status === 200) {
            people.value = res.data.people.data;
            totalData.value = res.data.people.total;
          }
          companies.value = [];
        } else {
          const res = await clientYellowInstance.get(
            `/companies?lang=${lang.value}`
          );
          if (res.status === 200 && res.data.companies) {
            let allCompanies = res.data.companies;

            if (subCategory.value) {
              companies.value = allCompanies.filter(
                (company) => company.category_id === parseInt(subCategory.value)
              );
            } else if (mainCategory.value) {
              companies.value = Array.from(
                allCompanies
                  .filter((company) =>
                    company.category_code.startsWith(
                      mainCategory.value.toString()
                    )
                  )
                  .reduce(
                    (map, company) => map.set(company.company_id, company),
                    new Map()
                  )
                  .values()
              );
            } else {
              companies.value = allCompanies;
            }
          }
          people.value = [];
        }
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      if (mainCategory.value) {
        fetchSubCategories();
        fetchCompanies();

        const mainCat = categoryNames.value.find(
          (cat) => cat.id === mainCategory.value
        );
        if (mainCat) {
          mainCategoryName.value =
            lang.value === "mn"
              ? `${mainCat.id} - ${mainCat.nameMn}`
              : `${mainCat.id} - ${mainCat.nameEn}`;
        }
      }
    });

    watch(
      () => route.params.id,
      (newId) => {
        routeId.value = newId;
        isRoute.value = route.path;
        mainCategory.value = newId;
        fetchSubCategories();
      }
    );

    watch([lang], () => {
      fetchSubCategories();
    });

    watch([mainCategory, subCategory], () => {
      fetchCompanies();
      companyName.value = null;
      personName.value = null;
    });

    watch(mainCategory, () => {
      subCategory.value = "";
      fetchSubCategories();
    });

    const emptyName = () => {
      companyName.value = null;
      personName.value = null;
    };

    // const filteredCompanies = computed(() => {
    //   const mongolianAlphabet = "АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯ";
    //   const englishAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    //   let filtered = companies.value;

    //   if (subCategory.value) {
    //     filtered = filtered.filter(
    //       (company) => company.category_id === parseInt(subCategory.value)
    //     );
    //   } else if (mainCategory.value) {
    //     filtered = filtered.filter((company) =>
    //       company.category_code.startsWith(mainCategory.value.toString())
    //     );
    //   }

    //   if (companyName.value) {
    //     filtered = filtered.filter((company) => {
    //       const name =
    //         company.company_info && company.company_info.translations
    //           ? lang.value === "mn"
    //             ? company.company_info.translations.name.mn
    //             : company.company_info.translations.name.en
    //           : "";

    //       return name.toLowerCase().includes(companyName.value.toLowerCase());
    //     });
    //   }

    //   return filtered.sort((a, b) => {
    //     const alphabet =
    //       lang.value === "mn" ? mongolianAlphabet : englishAlphabet;

    //     const nameA =
    //       a.company_info && a.company_info.translations
    //         ? lang.value === "mn"
    //           ? a.company_info.translations.name.mn
    //           : a.company_info.translations.name.en
    //         : "";

    //     const nameB =
    //       b.company_info && b.company_info.translations
    //         ? lang.value === "mn"
    //           ? b.company_info.translations.name.mn
    //           : b.company_info.translations.name.en
    //         : "";

    //     if (!nameA && nameB) return 1;
    //     if (nameA && !nameB) return -1;
    //     if (!nameA && !nameB) return 0;

    //     for (let i = 0; i < Math.min(nameA.length, nameB.length); i++) {
    //       let indexA = alphabet.indexOf(nameA[i]);
    //       let indexB = alphabet.indexOf(nameB[i]);

    //       if (indexA === -1) indexA = alphabet.length;
    //       if (indexB === -1) indexB = alphabet.length;

    //       if (indexA !== indexB) {
    //         return indexA - indexB;
    //       }
    //     }

    //     return nameA.length - nameB.length;
    //   });
    // });

    const filteredCompanies = computed(() => {
      const mongolianAlphabet = "АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯ";
      const englishAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      console.log("Filtering Companies...");
      let filtered = companies.value;

      if (subCategory.value) {
        filtered = filtered.filter(
          (company) => company.category_id === parseInt(subCategory.value)
        );
      } else if (mainCategory.value) {
        filtered = filtered.filter((company) =>
          company.category_code.startsWith(mainCategory.value.toString())
        );
      }

      if (companyName.value) {
        console.log(companyName.value);
        filtered = filtered.filter((company) => {
          const name =
            company.company_info && company.company_info.translations
              ? lang.value === "mn"
                ? company.company_info.translations.name.mn || ""
                : company.company_info.translations.name.en || ""
              : "";

          return name.toLowerCase().includes(companyName.value.toLowerCase());
        });
      }

      return filtered.sort((a, b) => {
        const alphabet =
          lang.value === "mn" ? mongolianAlphabet : englishAlphabet;

        const nameA =
          a.company_info && a.company_info.translations
            ? lang.value === "mn"
              ? a.company_info.translations.name.mn
              : a.company_info.translations.name.en
            : "";

        const nameB =
          b.company_info && b.company_info.translations
            ? lang.value === "mn"
              ? b.company_info.translations.name.mn
              : b.company_info.translations.name.en
            : "";

        const hasLogoA =
          a.company_info &&
          a.company_info.translations &&
          a.company_info.translations.logo
            ? 1
            : 0;

        const hasLogoB =
          b.company_info &&
          b.company_info.translations &&
          b.company_info.translations.logo
            ? 1
            : 0;

        if (hasLogoA !== hasLogoB) return hasLogoB - hasLogoA;

        if (!nameA && nameB) return 1;
        if (nameA && !nameB) return -1;
        if (!nameA && !nameB) return 0;

        for (let i = 0; i < Math.min(nameA.length, nameB.length); i++) {
          let indexA = alphabet.indexOf(nameA[i]);
          let indexB = alphabet.indexOf(nameB[i]);

          if (indexA === -1) indexA = alphabet.length;
          if (indexB === -1) indexB = alphabet.length;

          if (indexA !== indexB) {
            return indexA - indexB;
          }
        }

        return nameA.length - nameB.length;
      });
    });

    const filteredPeople = computed(() => {
      const mongolianAlphabet = "АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯ";
      const englishAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      let filtered = people.value;

      if (personName.value) {
        filtered = filtered.filter((person) => {
          const name =
            person.person_info?.translations?.name?.[lang.value] || "";
          return name.toLowerCase().includes(personName.value.toLowerCase());
        });
      }

      return filtered.sort((a, b) => {
        const alphabet =
          lang.value === "mn" ? mongolianAlphabet : englishAlphabet;

        const nameA = a.person_info?.translations?.name?.[lang.value] || "";
        const nameB = b.person_info?.translations?.name?.[lang.value] || "";

        if (!nameA && nameB) return 1;
        if (nameA && !nameB) return -1;
        if (!nameA && !nameB) return 0;

        for (let i = 0; i < Math.min(nameA.length, nameB.length); i++) {
          let indexA = alphabet.indexOf(nameA[i].toUpperCase());
          let indexB = alphabet.indexOf(nameB[i].toUpperCase());

          if (indexA === -1) indexA = alphabet.length;
          if (indexB === -1) indexB = alphabet.length;

          if (indexA !== indexB) {
            return indexA - indexB;
          }
        }

        return nameA.length - nameB.length;
      });
    });

    watchEffect(() => {
      if (companies.value) {
        totalData.value = filteredCompanies.value.length;
      }
    });

    const handleSearch = () => {
      if (companyName.value) {
        filteredCompanies;
      } else {
        filteredPeople;
      }
    };

    return {
      isRoute,
      routeId,
      lang,
      images,
      categoryNames,
      isLoading,
      companyName,
      personName,
      mainCategory,
      subCategory,
      companies,
      subCategories,
      emptyName,
      filteredCompanies,
      people,
      filteredPeople,
      setMainCategory,
      setSubCategory,
      totalData,
      mainCategoryName,
      subCategoryName,
      handleSearch,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>

<style scoped>
.mySwiper {
  --swiper-pagination-color: #cc4628;
  --swiper-pagination-bullet-size: 16px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  --swiper-button-prev: #cc4628;
}
</style>
