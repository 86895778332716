<template>
  <admin-navbar>
    <h1 class="font-bold text-lg">Лавлахад шинэ ангилал нэмэх</h1>

    <main class="flex flex-col gap-5">
      <div class="w-full">
        <label for="mainCategory"
          >Үндсэн ангилал:<span class="text-red-500">*</span></label
        >
        <select
          name="mainCategory"
          id="mainCategory"
          class="w-full border p-2"
          v-model="form.mainCategory"
        >
          <option disabled value="">Үндсэн ангилал"</option>
          <option value="01">01 - Уул уурхайн компани</option>
          <option value="02">02 - Ханган нийлүүлэгч</option>
          <option value="03">03 - Үйлчилгээ үзүүлэгч</option>
          <option value="04">04 - Бусад</option>
        </select>
      </div>

      <div class="w-full">
        <label for="nameMn"
          >Нэр /монголоор/:<span class="text-red-500">*</span></label
        >
        <input
          id="nameMn"
          name="nameMn"
          type="text"
          placeholder="Нэр"
          class="w-full p-2 border"
          v-model="form.nameMn"
        />
      </div>

      <div class="w-full">
        <label for="nameEn"
          >Нэр /англиар/:<span class="text-red-500">*</span></label
        >
        <input
          id="nameEn"
          name="nameEn"
          type="text"
          placeholder="Нэр"
          class="w-full p-2 border"
          v-model="form.nameEn"
        />
      </div>

      <div class="flex justify-center">
        <button
          @click="insertCategory"
          class="bg-blue-500 text-white p-2 group"
        >
          <font-awesome-icon
            icon="fa-solid fa-plus"
            class="mr-1 transition-transform duration-300 group-hover:rotate-90"
          />
          Нэмэх
        </button>
      </div>
    </main>
  </admin-navbar>

  <SuccessModal v-if="success" :success="success" />
  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import adminInstance from "@/lib/adminInstance";
import { ref } from "vue";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";

export default {
  name: "AdminYellowNewCategory",
  components: {
    AdminNavbar,
    SuccessModal,
    ErrorModal,
  },
  setup() {
    const form = ref({
      mainCategory: "01",
      nameMn: "",
      nameEn: "",
    });
    const success = ref(null);
    const error = ref(null);

    const resetForm = () => {
      form.value = {
        mainCategory: "01",
        nameMn: "",
        nameEn: "",
      };
    };

    const insertCategory = async () => {
      try {
        const res = await adminInstance.post(
          "/yellow-page/insert/category",
          form.value
        );
        if (res.status === 200) {
          success.value = "Амжилттай нэмэгдлээ.";
          resetForm();
          setTimeout(() => {
            success.value = null;
          }, 3000);
        } else {
          error.value = "Алдаа гарлаа.";
          setTimeout(() => {
            error.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    return { form, insertCategory, success, error };
  },
};
</script>
