<template>
  <admin-navbar>
    <main class="h-screen flex justify-center p-5 overflow-auto">
      <div>
        <!-- TOGGLE BUTTON -->
        <div class="flex justify-center my-8">
          <div class="bg-white rounded-full">
            <button
              @click="selectType(1)"
              class="w-[15rem] p-3 uppercase"
              :class="
                selectedType === 1 ? 'bg-[#CC4628] rounded-full text-white' : ''
              "
            >
              {{ lang === "mn" ? "1. Байгууллага" : "1. Company" }}
            </button>
            <button
              @click="selectType(2)"
              class="w-[15rem] p-3 uppercase"
              :class="
                selectedType === 2 ? 'bg-[#CC4628] rounded-full text-white' : ''
              "
            >
              {{ lang === "mn" ? "2. Зөвлөх инженер" : "2.Mining consultant" }}
            </button>
          </div>
        </div>

        <!-- FORM -->
        <YellowCompanyRegistration v-if="selectedType === 1" :approved="true" />
        <YellowConsultantRegistration
          v-else-if="selectedType === 2"
          :approved="true"
        />
      </div>
    </main>
  </admin-navbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import YellowCompanyRegistration from "@/containers/YellowPage/YellowCompanyRegistration.vue";
import YellowConsultantRegistration from "@/containers/YellowPage/YellowConsultantRegistration.vue";

export default {
  name: "AdminYellowInsert",
  components: {
    AdminNavbar,
    YellowCompanyRegistration,
    YellowConsultantRegistration,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const selectedType = ref(1);
    const selectType = (item) => {
      selectedType.value = item;
    };

    return { lang, selectedType, selectType };
  },
};
</script>
