<template>
  <main
    class="flex justify-center bg-[#f7f7f7] min-h-screen p-5 sm:p-10 font-helvetica"
  >
    <div class="w-full min-[1200px]:w-5/6">
      <!-- breadcrumb nav -->
      <div
        v-motion-fade-visible-once
        :enter="{
          transition: {
            delay: 3000,
            duration: 3000,
          },
        }"
        class="flex gap-1 sm:gap-2 text-sm"
      >
        <router-link
          to="/yellowpage"
          :class="
            isRoute === '/yellowpage' ? 'text-[#CC4628]' : 'text-gray-500'
          "
          class="hover:underline"
          >{{ lang === "mn" ? "Нүүр хуудас" : "Home" }}</router-link
        >
        <span class="text-gray-500">/</span>
        <router-link
          :to="`/yellowpage/category/${routeId}`"
          :class="
            isRoute === `/yellowpage/category/${routeId}`
              ? 'text-[#CC4628]'
              : 'text-gray-500'
          "
          class="hover:underline"
          >{{ categoryName }}</router-link
        >
      </div>

      <!-- title -->
      <h1
        v-motion-slide-visible-once-left
        :enter="{
          transition: {
            delay: 3000,
            duration: 3000,
          },
        }"
        class="border-l-4 border-[#09396C] text-[#09396C] text-lg sm:text-xl md:text-3xl lg:text-4xl uppercase font-bold pl-3 mt-8"
      >
        {{ categoryName }}
      </h1>

      <!-- Filters -->
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-10 mt-8"
      >
        <div class="flex">
          <span
            class="bg-[#CC4628] py-2 px-4 sm:py-3 sm:px-5 text-white text-center"
            ><font-awesome-icon icon="fa-solid fa-magnifying-glass"
          /></span>
          <input
            type="text"
            :placeholder="
              subCategory === 95
                ? lang === 'mn'
                  ? 'Инженерийн нэр'
                  : 'Surname'
                : lang === 'mn'
                ? 'Байгууллагын нэр'
                : 'Search company'
            "
            class="w-[12rem] sm:w-[15rem] border p-3"
            :v-model="subCategory === 95 ? personName : companyName"
          />
          <button
            @click="emptyName"
            class="bg-[#CC4628] py-2 px-4 sm:py-3 sm:px-5 text-white text-center group"
          >
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="transition-transform duration-300 group-hover:rotate-90"
            />
          </button>
        </div>

        <div class="flex">
          <span
            class="bg-[#CC4628] py-2 px-4 sm:py-3 sm:px-5 text-white text-center"
            ><font-awesome-icon icon="fa-solid fa-filter"
          /></span>
          <select
            name="mainCategory"
            id="mainCategory"
            class="w-[12rem] sm:w-[15rem] border p-3"
            v-model="mainCategory"
          >
            <option disabled value="">
              {{ lang === "mn" ? "Үндсэн ангилал" : "All categories" }}
            </option>
            <option value="01">
              {{ lang === "mn" ? "Уул уурхайн компани" : "Mining Companies" }}
            </option>
            <option value="02">
              {{ lang === "mn" ? "Ханган нийлүүлэгч" : "Supplier Companies" }}
            </option>
            <option value="03">
              {{ lang === "mn" ? "Үйлчилгээ үзүүлэгч" : "Service Companies" }}
            </option>
            <option value="04">
              {{ lang === "mn" ? "Бусад" : "Other Companies" }}
            </option>
          </select>
          <button
            @click="emptyMainCategory"
            class="bg-[#CC4628] py-2 px-4 sm:py-3 sm:px-5 text-white text-center group"
          >
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="transition-transform duration-300 group-hover:rotate-90"
            />
          </button>
        </div>

        <div class="flex">
          <span
            class="bg-[#CC4628] py-2 px-4 sm:py-3 sm:px-5 text-white text-center"
            ><font-awesome-icon icon="fa-solid fa-filter"
          /></span>
          <select
            name="subCategory"
            id="subCategory"
            class="w-[12rem] sm:w-[15rem] border p-3"
            v-model="subCategory"
          >
            <option disabled value="">
              {{ lang === "mn" ? "Дэд ангилал" : "All sub-categories" }}
            </option>
            <option v-for="sub in subCategories" :key="sub.id" :value="sub.id">
              {{ sub.name }}
            </option>
          </select>
          <button
            @click="emptySubCategory"
            class="bg-[#CC4628] py-2 px-4 sm:py-3 sm:px-5 text-white text-center group"
          >
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="transition-transform duration-300 group-hover:rotate-90"
            />
          </button>
        </div>
      </div>

      <div v-if="subCategory === 95" class="mt-5">
        <table
          v-if="filteredPeople.length > 0 && !isLoading"
          class="w-full border rounded-lg divide-y divide-gray-200"
        >
          <thead class="bg-[#09396C] text-white">
            <tr>
              <th class="px-4 py-2 text-center uppercase">Нэр</th>
              <th class="px-4 py-2 text-center uppercase">Мэргэжил</th>
              <th class="px-4 py-2 text-center uppercase">Зөвлөгөө</th>
              <th class="px-4 py-2 text-center uppercase">Утас</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="person in filteredPeople" :key="person.id">
              <td class="px-4 py-2 text-sm text-nowrap">
                {{
                  lang === "mn"
                    ? person.person_info?.translations?.name?.mn === "NULL"
                      ? ""
                      : person.person_info?.translations?.name?.mn
                    : person.person_info?.translations?.name?.en === "NULL"
                    ? ""
                    : person.person_info?.translations?.name?.en
                }}
              </td>
              <td class="px-4 py-2 text-sm">
                {{
                  lang === "mn"
                    ? person.person_info?.translations?.profession?.mn ===
                      "NULL"
                      ? ""
                      : person.person_info?.translations?.profession?.mn
                    : person.person_info?.translations?.profession?.en ===
                      "NULL"
                    ? ""
                    : person.person_info?.translations?.profession?.en
                }}
              </td>
              <td class="px-4 py-2 text-sm">
                {{
                  lang === "mn"
                    ? person.person_info?.translations?.advice_range?.mn ===
                      "NULL"
                      ? ""
                      : person.person_info?.translations?.advice_range?.mn
                    : person.person_info?.translations?.advice_range?.en ===
                      "NULL"
                    ? ""
                    : person.person_info?.translations?.advice_range?.en
                }}
              </td>
              <td class="px-4 py-2 text-sm text-center">
                {{ person.phone_number ? person.phone_number : "" }}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-else-if="filteredPeople.length === 0 && !isLoading"
          class="col-span-5 text-center mt-5"
        >
          {{ lang === "mn" ? "Илэрц олдсонгүй." : "No companies found." }}
        </div>

        <div v-else class="flex justify-center items-center w-full h-[50vh]">
          <BarLoading />
        </div>
      </div>

      <div v-else>
        <div
          v-if="filteredCompanies.length === 0 && !isLoading"
          class="col-span-5 text-center mt-5"
        >
          {{ lang === "mn" ? "Илэрц олдсонгүй." : "No companies found." }}
        </div>

        <div v-else-if="filteredCompanies.length > 0 && !isLoading">
          <div
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-5 w-full mt-5"
          >
            <router-link
              v-for="company in filteredCompanies"
              :key="company.company_id"
              :to="`/yellowpage/detail/${company.company_id}`"
              class="text-center p-5 hover:scale-105"
            >
              <img
                v-if="company.company_info?.translations?.logo !== 'NULL'"
                :src="company.company_info.translations.logo"
                alt="logo"
                class="object-contain"
              />
              <h1
                v-else
                class="uppercase text-gray-300 text-center text-4xl mb-3"
              >
                ЛОГО
              </h1>
              {{
                lang === "mn"
                  ? company.company_info?.translations?.name?.mn || ""
                  : company.company_info?.translations?.name?.en || ""
              }}
            </router-link>
          </div>
        </div>

        <div v-else class="flex justify-center items-center w-full h-[50vh]">
          <BarLoading />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import clientYellowInstance from "@/lib/yellowClientInstance";
import { ref, computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import BarLoading from "@/components/ui/BarLoading.vue";

export default {
  name: "YellowCategoryPage",
  components: {
    BarLoading,
  },
  setup() {
    const route = useRoute();
    const isRoute = ref(route.path);
    const routeId = ref(route.params.id);

    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const categoryNames = {
      "01": { mn: "Уул уурхайн компани", en: "Mining Companies" },
      "02": { mn: "Ханган нийлүүлэгч", en: "Supplier Companies" },
      "03": { mn: "Үйлчилгээ үзүүлэгч", en: "Service Providers" },
      "04": { mn: "Бусад", en: "Others" },
    };

    const categoryName = computed(() => {
      return categoryNames[routeId.value]?.[lang.value] || "Unknown";
    });

    const isLoading = ref(false);
    const companyName = ref(null);
    const personName = ref(null);
    const mainCategory = ref(routeId.value);
    const subCategory = ref("");
    const subCategories = ref([]);
    const companies = ref([]);
    const people = ref([]);

    const fetchSubCategories = async () => {
      try {
        const res = await clientYellowInstance.get(
          `/categories?main=${mainCategory.value}&language=${lang.value}`
        );

        if (res.status === 200) {
          subCategories.value = res.data.subCategories;
        }
      } catch (err) {
        return err;
      }
    };

    const fetchCompanies = async () => {
      try {
        isLoading.value = true;

        if (subCategory.value === 95) {
          const res = await clientYellowInstance.get(
            `/people?lang=${lang.value}`
          );
          if (res.status === 200) {
            people.value = res.data.people;
          }
          companies.value = [];
        } else {
          const res = await clientYellowInstance.get(
            `/companies?lang=${lang.value}`
          );
          if (res.status === 200 && res.data.companies) {
            let allCompanies = res.data.companies;
            console.log(allCompanies);

            if (subCategory.value) {
              companies.value = allCompanies.filter(
                (company) => company.category_id === parseInt(subCategory.value)
              );
            } else if (mainCategory.value) {
              // companies.value = allCompanies.filter((company) =>
              //   company.category_code.startsWith(mainCategory.value.toString())
              // );

              companies.value = Array.from(
                allCompanies
                  .filter((company) =>
                    company.category_code.startsWith(
                      mainCategory.value.toString()
                    )
                  )
                  .reduce(
                    (map, company) => map.set(company.company_id, company),
                    new Map()
                  )
                  .values()
              );
            } else {
              companies.value = allCompanies;
            }
          }
          people.value = [];
        }
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      if (mainCategory.value) {
        fetchSubCategories();
        fetchCompanies();
      }
    });

    watch(
      () => route.params.id,
      (newId) => {
        routeId.value = newId;
        isRoute.value = route.path;
        mainCategory.value = newId;
        fetchSubCategories();
      }
    );

    watch([lang], () => {
      fetchSubCategories();
    });

    watch([mainCategory, subCategory], () => {
      fetchCompanies();
    });

    watch(mainCategory, () => {
      subCategory.value = "";
      fetchSubCategories();
    });

    const emptyName = () => {
      companyName.value = null;
      personName.value = null;
    };

    const emptyMainCategory = () => {
      mainCategory.value = "";
    };

    const emptySubCategory = () => {
      subCategory.value = "";
    };

    const filteredCompanies = computed(() => {
      const mongolianAlphabet = "АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯ";
      const englishAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      let filtered = companies.value;

      if (subCategory.value) {
        filtered = filtered.filter(
          (company) => company.category_id === parseInt(subCategory.value)
        );
      } else if (mainCategory.value) {
        filtered = filtered.filter((company) =>
          company.category_code.startsWith(mainCategory.value.toString())
        );
      }

      if (companyName.value) {
        filtered = filtered.filter((company) => {
          const name =
            company.company_info && company.company_info.translations
              ? lang.value === "mn"
                ? company.company_info.translations.name.mn
                : company.company_info.translations.name.en
              : "";

          return name.toLowerCase().includes(companyName.value.toLowerCase());
        });
      }

      return filtered.sort((a, b) => {
        const alphabet =
          lang.value === "mn" ? mongolianAlphabet : englishAlphabet;

        const nameA =
          a.company_info && a.company_info.translations
            ? lang.value === "mn"
              ? a.company_info.translations.name.mn
              : a.company_info.translations.name.en
            : "";

        const nameB =
          b.company_info && b.company_info.translations
            ? lang.value === "mn"
              ? b.company_info.translations.name.mn
              : b.company_info.translations.name.en
            : "";

        if (!nameA && nameB) return 1;
        if (nameA && !nameB) return -1;
        if (!nameA && !nameB) return 0;

        for (let i = 0; i < Math.min(nameA.length, nameB.length); i++) {
          let indexA = alphabet.indexOf(nameA[i]);
          let indexB = alphabet.indexOf(nameB[i]);

          if (indexA === -1) indexA = alphabet.length;
          if (indexB === -1) indexB = alphabet.length;

          if (indexA !== indexB) {
            return indexA - indexB;
          }
        }

        return nameA.length - nameB.length;
      });
    });

    const filteredPeople = computed(() => {
      const mongolianAlphabet = "АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯ";
      const englishAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      let filtered = people.value;

      if (personName.value) {
        filtered = filtered.filter((person) => {
          const name =
            person.person_info?.translations?.name?.[lang.value] || "";
          return name.toLowerCase().includes(personName.value.toLowerCase());
        });
      }

      return filtered.sort((a, b) => {
        const alphabet =
          lang.value === "mn" ? mongolianAlphabet : englishAlphabet;

        const nameA = a.person_info?.translations?.name?.[lang.value] || "";
        const nameB = b.person_info?.translations?.name?.[lang.value] || "";

        if (!nameA && nameB) return 1;
        if (nameA && !nameB) return -1;
        if (!nameA && !nameB) return 0;

        for (let i = 0; i < Math.min(nameA.length, nameB.length); i++) {
          let indexA = alphabet.indexOf(nameA[i].toUpperCase());
          let indexB = alphabet.indexOf(nameB[i].toUpperCase());

          if (indexA === -1) indexA = alphabet.length;
          if (indexB === -1) indexB = alphabet.length;

          if (indexA !== indexB) {
            return indexA - indexB;
          }
        }

        return nameA.length - nameB.length;
      });
    });

    return {
      isRoute,
      routeId,
      lang,
      isLoading,
      companyName,
      personName,
      mainCategory,
      subCategory,
      companies,
      subCategories,
      categoryName,
      emptyName,
      emptyMainCategory,
      emptySubCategory,
      filteredCompanies,
      people,
      filteredPeople,
    };
  },
};
</script>
