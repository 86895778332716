<template>
  <div
    class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[200] h-full w-full"
  >
    <div
      class="h-[90vh] w-3/4 bg-white rounded p-5 space-y-5 overflow-y-scroll"
    >
      <div class="flex justify-end">
        <button
          @click="closeModal()"
          class="flex justify-center items-center bg-blue-200 h-8 w-8 rounded-full text-sm text-white hover:bg-blue-300"
        >
          <font-awesome-icon icon="fa-solid fa-x" />
        </button>
      </div>

      <section class="mt-5">
        <h2 class="text-lg font-bold mt-5">Зөвлөх инженерийн мэдээлэл</h2>
        <div v-if="person?.about" class="grid gap-4">
          <div
            v-for="(about, index) in person.about"
            :key="index"
            class="flex flex-col gap-2 mt-3"
          >
            <h3 class="uppercase text-blue-400 text-base font-bold">
              {{ about.lang === "mn" ? "Монголоор" : "Англиар" }}
            </h3>
            <label class="font-semibold">{{
              about.lang === "mn" ? "Нэр" : "Name"
            }}</label>
            <input
              v-model="about.name"
              class="border p-2"
              placeholder="Инженерийн нэр"
            />

            <label class="font-semibold">{{
              about.lang === "mn" ? "Утасны дугаар" : "Phone Number"
            }}</label>
            <input
              v-model="person.person.phone_number"
              class="border p-2"
              placeholder="Дугаар"
            />

            <label class="font-semibold">{{
              about.lang === "mn" ? "Мэргэжил" : "Profession"
            }}</label>
            <input
              v-model="about.profession"
              class="border p-2"
              placeholder="Мэргэжил"
            />

            <label class="font-semibold">{{
              about.lang === "mn" ? "Зөвлөгөөний хүрээ" : "Advice Range"
            }}</label>
            <input
              v-model="about.advice_range"
              class="border p-2"
              placeholder="Зөвлөгөө"
            />
          </div>
        </div>

        <div
          v-if="person?.person"
          class="flex justify-center gap-8 mt-8 font-semibold"
        >
          <div class="flex items-center gap-1">
            <input
              type="checkbox"
              name="approval"
              id="approval"
              v-model="person.person.approval"
            />
            <label
              for="approval"
              :class="person.person.approval ? '' : 'text-gray-500'"
              >Нийтлэхийг зөвшөөрсөн эсэх</label
            >
          </div>
        </div>

        <div class="flex justify-center gap-5 mt-8">
          <button @click="updatePerson" class="bg-green text-white p-2 rounded">
            <font-awesome-icon icon="fa-solid fa-bookmark" />
            Мэдээллийг хадгалах
          </button>

          <button
            @click="deletePerson(person.about[0]?.person_id)"
            class="border border-red-500 p-2 rounded text-red-500 hover:bg-red-500 hover:text-white"
          >
            <font-awesome-icon icon="fa-solid fa-trash" />
            Мэдээллийг устгах
          </button>
        </div>
      </section>
    </div>
  </div>

  <SuccessModal v-if="success" :success="success" />
  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import adminInstance from "@/lib/adminInstance";
import { onMounted, ref, watch } from "vue";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";

export default {
  name: "YellowEditConsultantModal",
  props: {
    id: Number,
  },
  emits: ["closeModal"],
  components: {
    SuccessModal,
    ErrorModal,
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit("closeModal");
    };

    const personId = ref(props.id);
    const person = ref({});
    const success = ref(null);
    const error = ref(null);

    const fetchPerson = async () => {
      try {
        const res = await adminInstance.get(
          `/yellow-page/get/person?id=${personId.value}`
        );
        if (res.status === 200) {
          person.value = res.data.person;
          console.log(person.value);
        }
      } catch (err) {
        error.value = "Байгууллагын мэдээлийг авахад алдаа гарлаа";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    onMounted(() => {
      fetchPerson();
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          fetchPerson();
        }
      },
      { immediate: true }
    );

    const updatePerson = async () => {
      try {
        const res = await adminInstance.post(
          "/yellow-page/update/person",
          person.value
        );
        if (res.status === 200) {
          success.value = "Амжилттай шинэчлэгдлээ.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Мэдээллийг хадгалахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const deletePerson = async (id) => {
      try {
        if (!window.confirm(`Мэдээллийг устгах уу?`)) {
          return;
        }

        const res = await adminInstance.delete(
          `/yellow-page/delete/person?id=${id}`
        );
        if (res.status === 200) {
          success.value = "Амжилттай устгалаа.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
          closeModal();
        }
      } catch (err) {
        error.value = "Мэдээллийг устгахад алдаа гарлаа";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    return {
      closeModal,
      personId,
      person,
      success,
      error,
      updatePerson,
      deletePerson,
    };
  },
};
</script>
