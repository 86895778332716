<template>
  <div class="my-8 p-4 border border-gray-200 rounded shadow">
    <form @submit.prevent="submitCategory" class="space-y-4">
      <div>
        <label for="categoryCode" class="block mb-1 font-medium text-gray-700"
          >category code</label
        >
        <input
          v-model="categoryForm.categoryCode"
          type="text"
          id="categoryCode"
          name="categoryCode"
          class="border border-gray-300 rounded w-full p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>

      <div>
        <label for="name" class="block mb-1 font-medium text-gray-700"
          >category name</label
        >
        <input
          v-model="categoryForm.name"
          type="text"
          id="name"
          name="name"
          class="border border-gray-300 rounded w-full p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
          @keyup.enter="submitCategory"
        />
      </div>

      <div>
        <label for="lang" class="block mb-1 font-medium text-gray-700"
          >language</label
        >
        <input
          v-model="categoryForm.lang"
          type="text"
          id="lang"
          name="lang"
          class="border border-gray-300 rounded w-full p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>

      <button
        type="submit"
        class="bg-blue-600 text-white rounded py-2 px-4 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        Нэмэх
      </button>
    </form>
  </div>

  <div class="mx-auto grid grid-cols-4 gap-2" v-if="categories.length">
    <div
      class="col-span-1 border-2 border-gray-100 p-1 bg-gray-50"
      v-for="category in categories"
      :key="category.id"
    >
      <p class="">{{ category?.category_code }}</p>
      <p class="text-sm">{{ category?.translation_name }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "YellowPageAddInfo",
  setup() {
    const categoryForm = ref({
      categoryCode: "",
      lang: "en",
      name: "",
    });

    const categories = ref([]);

    const fetchCategories = async () => {
      try {
        const response = await adminInstance.get("/yellow-page/categories");
        categories.value = response.data.categories;
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const submitCategory = async () => {
      try {
        const response = await adminInstance.put("/yellow-page/category", {
          categoryCode: categoryForm.value.categoryCode,
          lang: categoryForm.value.lang,
          name: categoryForm.value.name,
        });
        if (response.status === 201) {
          alert(response.data.message);
          await fetchCategories();
        }

        categoryForm.value.categoryCode = "";
        categoryForm.value.name = "";
      } catch (error) {
        console.error("Error creating category:", error);
        alert(error.response && error.response.data.message);
      }
    };

    onMounted(async () => {
      await fetchCategories();
    });

    return {
      categories,
      categoryForm,
      submitCategory,
    };
  },
};
</script>
