<template>
  <main class="flex justify-center font-zona">
    <div class="w-full min-[1200px]:w-4/5 max-[1200px]:mx-3 py-5 md:py-10">
      <img
        v-if="lang === 'mn'"
        src="../../../assets/images/miningMongolia/home-pages-cards/hariutslagatai uul uurhai logo.png"
        alt="responsible mining"
        class="w-60 h-20 object-contain"
      />
      <img
        v-else
        src="../../../assets/images/miningMongolia/home-pages-cards/hariutslagatai uul uurhai logo en.png"
        alt="responsible mining"
        class="w-60 h-20 object-contain"
      />

      <h1 class="text-green text-xl font-bold mt-3">
        {{
          lang === "mn"
            ? "Хариуцлагатай уул уурхай гэж юу вэ?"
            : "What is a responsible mining?"
        }}
      </h1>
      <p class="text-sm text-justify mt-3 tracking-wide">
        {{
          lang === "mn"
            ? "Уурхайг ашигласнаар улс орны хөгжилд нь нэмэртэй, ард түмний амьдралд тусгай, байгаль орчин, хүний эрүүл мэндэд хохиролгүй байхыг хэлэх ба энэ нь уул уурхайг төлөвлөж эхлэхээс авахуулаад дуусах хүртэл хийгдэх олон талын цогц арга хэмжээ юм. Монголын уул уурхайн Үндэсний ассоциаци нь 2006 оноос Монгол Улсад хариуцлагатай уул уурхайг хөгжүүлэх санаачилгыг гаргаж эрхэм зорилгоо “Монгол улсын эрдэс баялгийн салбарт хариуцлагатай уул уурхайг хөгжүүлэх үйл хэрэгт идэвхитэй оролцох…” гэж тодорхойлж, энэхүү зарчмийг хэрэгжүүлэхээр зорин ажиллаж байгаа билээ. Монголын уул уурхайн компаниудын нийгмийн хариуцлагаа дээшлүүлэх, тэдгээрийн тогтвортой хөгжлийг хангах, тэдгээрийн дунд манлайлагчдийг бий болгоход дэмжлэг үзүүлэх зорилгоор энэхүү кодексийг МУУҮА-ийн ажлын алба боловсруулж, Ассоциацийн зөвлөл хэлэлцэж Чуулганы хуралдаанаар дэмжигдэн хэрэгжүүлж байна."
            : "The utilization of mines should contribute to the country's development, enrich people's lives, while ensuring the preservation of the environment and human health. The Mongolian National Mining Association has launched a responsible mining initiative in Mongolia since 2006 to 'actively participate in the development of responsible mountains in Mongolia`s mineral industry' and working to implement this principle. The CODEX is being developed by the Mongolian National Mining Association team and is being implemented by the Association Council in a conference session to improve social responsibility for Mongolia's mining companies, provide sustainable development, and promote the creation of patrons among them."
        }}
      </p>
      <p class="text-sm text-justify mt-3 tracking-wide">
        {{
          lang === "mn"
            ? "Монголын уул уурхайн үндэсний ассоциаци нь эрдэс баялгийн салбарын тогтвортой байдлыг бий болгох, тус салбарт үйл ажиллагаа явуулж буй компаниудыг чадавхижуулах зорилгоор “Хариуцлагатай уул уурхай” хөтөлбөрийг боловсруулан хэрэгжилтийг ханган ажиллуулж байна. Тус хөтөлбөрийг НҮБХХ-ийн “Байгаль орчны нөөцийн тогтвортой удирдлагыг хангах байгаль орчны засаглал төсөл”-ийн хүрээнд 2018 онд боловсруулсан “Хариуцлагатай уул уурхайн жишиг шаардлага ба үнэлгээний аргачлал”-д суурилан боловсруулсан бөгөөд 2019 онд МУУҮА-ийн санаачилгаар “Хариуцлагатай Уул уурхайн сайн дурын кодекс”-т олборлох салбарт үйл ажиллагаа явуулж буй аж ахуйн нэгжүүдийг нэгтгэх зорилгоор зохион байгуулсан “Хариуцлагатай Нэгд” аянд салбарын тэргүүлэх 8 КОМПАНИ нэгдэж харилцан ойлголцлын санамж бичигт гарын үсэг зурснаар тус хөтөлбөр нь хэрэгжиж эхэлсэн."
            : "The Mongolian National Mining Association is implementing a 'Responsible Mining' program to stabilize the mineral industry andempower companies operating in the sector.  The program was based on the 'Responsible MiningStandards and Assessment Methods' developed in 2018 within the frameworkof the UNIDO`s 'Environmental Governance Project to Ensure SustainableManagement of Environmental Resources'. In 2019, 8 leading companies in the industry signed a memorandum of understanding in the 'Responsibly Join' campaign organized by the MongolianNational Mining Association to unite companies operating in the mining sector in the 'Voluntary Responsible Mining Codex' started to be implemented."
        }}
      </p>
      <div
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 justify-center items-center my-5 gap-x-10 gap-y-5"
      >
        <a
          v-for="logo in logos"
          :key="logo.id"
          :href="logo.link"
          target="_blank"
          class="flex justify-center items-center hover:scale-105"
        >
          <img :src="logo.logo" alt="logo" />
        </a>
      </div>
      <p class="text-sm text-justify tracking-wide">
        {{
          lang === "mn"
            ? "Энэхүү хөтөлбөр нь Хариуцлагатай уул уурхайн сайн дурын кодекс, кодексийг хэрэгжүүлэх заавар гэсэн 3 баримт бичгээс бүрддэг. Тухайн хөтөлбөрт нэгдэж байгаа байгууллага эхний жилд кодексийг нэвтрүүлэх сургалтанд хамрагдах, кодексийн дагуу өөрийн үнэлгээ хийж тайлан гаргах, өөрийн үнэлгээ хийх явцдаа зөвлөгөө авах гэсэн үе шатуудыг дамжих бол МУУҮА-ийн Зөвлөлийн дэргэдэх “Олон талт зөвлөл”-р хэлэлцүүлж зэрэглэл тогтоох, олон нийтэд зарлах ажлыг зохион байгуулах юм. Тус хөтөлбөр нь Хариуцлагатай уул уурхайн 8 зарчим, 5 хамрах хүрээ 29 шаардлагаас бүрдэх ба дээрх шаардлагуудыг үр дүнгийн менежментийн тогтолцооны Төлөвлө-Гүйцэтгэ-Шалга-Сайжруул (PDAC) гэсэн аргачлалаар хэрэгжүүлэх юм."
            : "The program consists of The Voluntary Responsible Mining Codex, the Instructions to Implement the Codex, and the Instructions to Self-Evaluation.  In the first year, the organization that joins the program will go through the stages of training to introduce the codex, make a self-assessment report according to the codex, and get advice during the self-evaluation process. The Mongolian National Mining Association will distribute the report issued by the codex to the public,discuss it with the 'Multilateral Council' attached to the council,and organize the work of ranking and announcing it to the public. The Responsible Mining Program consists of 8 projects, 5 scopes, and 29 requirements, and these requirements are implemented by the methods which are Plan-Do-Check-Act (PDCA) methodology of an effective management system."
        }}
      </p>
      <img
        v-if="lang === 'mn'"
        src="https://miningmongolia.mn/static/1721589852421.png"
        alt="1"
        class="w-full mt-5"
      />
      <img
        v-else
        src="https://miningmongolia.mn/static/1721590015640.png"
        alt="1"
        class="w-full mt-5"
      />

      <img
        v-if="lang === 'mn'"
        src="https://miningmongolia.mn/static/1721589959192.png"
        alt="2"
        class="w-full mt-5"
      />
      <img
        v-else
        src="https://miningmongolia.mn/static/1721590033638.png"
        alt="2"
        class="w-full mt-5"
      />

      <div class="mt-5">
        <h1 v-if="documents.length > 0" class="font-bold text-lg uppercase">
          {{ lang === "mn" ? "Холбогдох материалууд:" : "Related materials:" }}
        </h1>
        <div
          v-if="documents.length > 0"
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 mt-3"
        >
          <button
            v-for="doc in documents"
            :key="doc.id"
            @click="downloadFile(doc.pdf, doc.title)"
            class="bg-gray-100 py-3 text-green text-sm"
          >
            <font-awesome-icon icon="fa-solid fa-download" class="mr-2" />
            {{ doc.title }}
          </button>
        </div>

        <h1 class="font-bold text-lg uppercase mt-8">
          {{ lang === "mn" ? "Өөрийн үнэлгээ" : "Self-assessment" }}
        </h1>
        <router-link
          to="/mm/assessment"
          class="flex justify-between items-center bg-blue-500 p-3 text-white w-[17rem] hover:scale-105 mt-3"
        >
          <span>
            <font-awesome-icon icon="fa-solid fa-list-check" class="mr-1" />
            Өөрийн үнэлгээг хийх
          </span>
          <font-awesome-icon icon="fa-solid fa-angle-right" />
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "ResponsibleMining",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const documents = ref([]);
    const logos = ref([]);

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/get/responsible-logo");
        if (res.status === 200) {
          logos.value = res.data.logos;
        }
        const docRes = await clientInstance.get("/get/document-pdf");
        if (docRes.status === 200) {
          documents.value = docRes.data.document;
        }
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const downloadFile = (pdfUrl, title) => {
      fetch(pdfUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", title + ".pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Error downloading file:", error));
    };

    return { lang, isLoading, documents, logos, downloadFile };
  },
};
</script>
