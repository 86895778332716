<template>
  <admin-navbar>
    <h1 class="font-bold text-lg">Ангилал</h1>

    <div class="bg-white rounded shadow">
      <h6 class="text-base border-t-4 border-t-blue-500 rounded-t border-b p-3">
        Лавлахын ангиллууд
      </h6>

      <div class="flex justify-between items-center p-3 text-xs">
        <div class="flex items-center gap-3">
          <label for="selectedType">Үндсэн ангилал:</label>
          <select
            name="selectedType"
            id="selectedType"
            class="p-1 border rounded"
            v-model="selectedType"
          >
            <option value="" disabled>Хайлтын төрөл</option>
            <option value="all">Бүгд</option>
            <option value="01">Уул уурхай компани</option>
            <option value="02">Ханган нийлүүлэгч</option>
            <option value="03">Үйлчилгээ үзүүлэгч</option>
            <option value="04">Бусад</option>
          </select>
        </div>

        <div class="flex items-center gap-3">
          <label for="selectedLanguage">Хэл:</label>
          <select
            name="selectedLanguage"
            id="selectedLanguage"
            class="p-1 border rounded"
            v-model="selectedLanguage"
          >
            <option value="" disabled>Хайлтын төрөл</option>
            <option value="mn">Монгол</option>
            <option value="en">Англи</option>
          </select>
        </div>

        <div class="space-x-3">
          <span>Хуудас бүрт:</span>
          <select
            name="dataPerPage"
            id="dataPerPage"
            class="border rounded p-1 text-blue-500"
            v-model="dataPerPage"
          >
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>

        <span>Нийт ангиллын тоо: {{ totalCategoryCount }} </span>

        <div class="flex items-center gap-3">
          <span>Нийт хуудас: {{ totalPages }} </span>
          <PaginationCart
            :totalPages="totalPages"
            :modelValue="currentPage"
            :style="{
              color: 'blue-500',
              height: 7,
              width: 7,
              fontSize: 'xs',
            }"
            @page-changed="onPageChanged"
          />
        </div>
      </div>

      <div
        v-if="categories.length > 0 && !isLoading"
        class="p-3 space-y-5 text-xs"
      >
        <hot-table :settings="hotSettings" :data="categories"></hot-table>
      </div>

      <div
        v-else-if="categories.length === 0 && !isLoading"
        class="flex justify-center items-center h-[50vh]"
      >
        Хүсэлт хоосон байна
      </div>

      <div v-else class="flex justify-center items-center h-[50vh]">
        <SpinLoading />
      </div>
    </div>
  </admin-navbar>

  <YellowCategoryModal
    v-if="isModal"
    :cat="category"
    @closeModal="closeModal"
  />
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { onMounted, ref, watch } from "vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";
import YellowCategoryModal from "@/containers/YellowPage/admin/YellowCategoryModal.vue";

registerAllModules();

export default {
  name: "AdminYellowCategory",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    HotTable,
    YellowCategoryModal,
  },
  setup() {
    const isLoading = ref(false);
    const categories = ref([]);
    const selectedType = ref("all");
    const selectedLanguage = ref("mn");
    const dataPerPage = ref(20);
    const totalCategoryCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const fetchCategories = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/yellow-page/get/categories?type=${selectedType.value}&lang=${selectedLanguage.value}&page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        categories.value = res.data.categories;
        totalPages.value = res.data.totalPages;
        totalCategoryCount.value = res.data.total;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchCategories();
    });

    watch([selectedType, dataPerPage, selectedLanguage], () => {
      currentPage.value = 1;
      fetchCategories();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchCategories();
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center items-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: ["Үйлдэл", "Ангиллын код", "Ангиллын нэр"],
      columns: [
        {
          readOnly: true,
          renderer: function (inst, td, row) {
            const category = categories.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 448c0 35.3 28.7 64 64 64l160 0 0-128c0-17.7 14.3-32 32-32l128 0 0-288c0-35.3-28.7-64-64-64L64 0C28.7 0 0 28.7 0 64L0 448zM171.3 75.3l-96 96c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l96-96c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zm96 32l-160 160c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l160-160c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zM384 384l-128 0 0 128L384 384z"/></svg>
                      <span>Нэр өөрчлөх</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                showModal(category);
              });
            }
          },
        },
        {
          data: "category_code",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "name",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "65vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const isModal = ref(false);
    const category = ref(null);
    const showModal = (item) => {
      category.value = item;
      isModal.value = true;
    };

    const closeModal = () => {
      isModal.value = false;
      fetchCategories();
    };

    return {
      isLoading,
      categories,
      dataPerPage,
      totalCategoryCount,
      totalPages,
      currentPage,
      onPageChanged,
      selectedType,
      fetchCategories,
      hotSettings,
      selectedLanguage,
      category,
      isModal,
      showModal,
      closeModal,
    };
  },
};
</script>
