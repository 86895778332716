<template>
  <div
    class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[200] h-full w-full"
  >
    <div
      class="h-[90vh] w-3/4 bg-white rounded p-5 space-y-5 overflow-y-scroll"
    >
      <div class="flex justify-end">
        <button
          @click="closeModal()"
          class="flex justify-center items-center bg-blue-200 h-8 w-8 rounded-full text-sm text-white hover:bg-blue-300"
        >
          <font-awesome-icon icon="fa-solid fa-x" />
        </button>
      </div>

      <section class="mt-5">
        <h2 class="text-lg font-bold mt-5">Ангиллын нэр өөрчлөх</h2>

        <div v-if="category?.name" class="flex flex-col gap-2 mt-3 text-xs">
          <h6 class="font-semibold">Ангиллын код</h6>
          <p class="border p-2">{{ category.category_code }}</p>

          <label class="font-semibold">Ангиллын нэр</label>
          <input
            v-model="category.name"
            class="border p-2"
            placeholder="Ангилал"
          />
        </div>

        <div class="flex justify-center gap-5 mt-8 text-xs">
          <button
            @click="updateCategory"
            class="bg-green text-white p-2 rounded"
          >
            <font-awesome-icon icon="fa-solid fa-bookmark" class="mr-1" />
            Хадгалах
          </button>
        </div>
      </section>
    </div>
  </div>

  <SuccessModal v-if="success" :success="success" />
  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import adminInstance from "@/lib/adminInstance";
import { ref } from "vue";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";

export default {
  name: "YellowCategoryModal",
  props: {
    cat: Object,
  },
  emits: ["closeModal"],
  components: {
    SuccessModal,
    ErrorModal,
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit("closeModal");
    };

    const category = ref(props.cat);
    const success = ref(null);
    const error = ref(null);

    const updateCategory = async () => {
      try {
        const res = await adminInstance.post(
          "/yellow-page/update/category",
          category.value
        );
        if (res.status === 200) {
          success.value = "Амжилттай шинэчлэгдлээ.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Мэдээллийг хадгалахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    return {
      closeModal,
      category,
      success,
      error,
      updateCategory,
    };
  },
};
</script>
