<template>
  <hot-table :settings="hotSettings" :data="requests"></hot-table>

  <YellowEditConsultantModal
    v-if="isModal"
    :id="personId"
    @closeModal="closeModal"
  />
</template>

<script>
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";
import { ref } from "vue";
import YellowEditConsultantModal from "./YellowEditConsultantModal.vue";

registerAllModules();

export default {
  name: "YellowConsultantTable",
  components: {
    HotTable,
    YellowEditConsultantModal,
  },
  props: {
    data: Array,
  },
  emits: ["fetchData"],
  setup(props, { emit }) {
    const fetchData = () => {
      emit("fetchData");
    };

    const requests = ref(props.data);

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center items-center p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Үйлдэл",
        "Инженерийн нэр",
        "Утасны дугаар",
        "Хүсэлт гаргасан огноо",
      ],
      columns: [
        {
          readOnly: true,
          renderer: function (inst, td, row) {
            const request = requests.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                  <button class="flex items-center bg-sky-500 py-1 px-2 rounded edit-btn">
                      <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 448c0 35.3 28.7 64 64 64l160 0 0-128c0-17.7 14.3-32 32-32l128 0 0-288c0-35.3-28.7-64-64-64L64 0C28.7 0 0 28.7 0 64L0 448zM171.3 75.3l-96 96c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l96-96c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zm96 32l-160 160c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l160-160c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zM384 384l-128 0 0 128L384 384z"/></svg>
                      <span>Дэлгэрэнгүй</span>
                  </button>
              </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                showModal(request.id);
              });
            }
          },
        },
        {
          data: "person_info",
          readOnly: true,
          renderer: function (instance, td, row, col, prop, value) {
            const personName =
              value && value.translations && value.translations.name
                ? value.translations.name.mn || ""
                : "";

            td.innerText = personName;
            td.classList.add("htCenter", "htMiddle");
          },
        },
        {
          data: "phone_number",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "created_at",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "65vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const isModal = ref(false);
    const personId = ref(null);
    const showModal = (id) => {
      if (id) {
        personId.value = id;
      }
      isModal.value = true;
    };

    const closeModal = () => {
      isModal.value = false;
      fetchData();
    };

    return {
      requests,
      hotSettings,
      isModal,
      showModal,
      personId,
      fetchData,
      closeModal,
    };
  },
};
</script>
