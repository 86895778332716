<template>
  <AdminNavbar>
    <h1 class="font-bold text-lg">Цэс</h1>
    <!-- 1st div -->
    <div class="rounded bg-white">
      <div class="border-b p-3 border-t-4 border-t-blue-500 rounded-t">
        <h6 class="text-base">Вэбсайтын цэс</h6>
      </div>

      <div v-if="!isLoading && categories.length > 0" class="flex gap-10 p-3">
        <!-- List of URL -->
        <div class="rounded shadow h-fit w-1/2">
          <h6 class="border-b p-3">Цэсний жагсаалт</h6>
          <ul class="p-3 space-y-3">
            <li v-for="(navbar, index) in parentCategories" :key="index">
              <!-- parent menu -->
              <div class="flex items-center justify-between uppercase">
                <div class="flex items-center gap-2">
                  <button
                    v-if="navbar.children && navbar.children.length > 0"
                    @click="toggleChildMenu(navbar.cid)"
                    class="bg-[#28a745] px-1 py-0.5 rounded text-white text-xs hover:bg-[#208537]"
                  >
                    <font-awesome-icon
                      v-if="currentOpenChildMenu === navbar.cid"
                      icon="fa-solid fa-minus"
                    />
                    <font-awesome-icon v-else icon="fa-solid fa-add" />
                  </button>
                  <div
                    v-else
                    class="bg-gray-200 px-1 py-0.5 rounded text-white text-xs cursor-not-allowed"
                  >
                    <font-awesome-icon icon="fa-solid fa-add" />
                  </div>
                  <h1 class="min-w-[17rem] text-nowrap">
                    {{ navbar.category }}
                  </h1>
                </div>
                <div class="text-white text-xs">
                  <!-- <button
                    v-if="index !== 0"
                    class="bg-gray-500 hover:bg-gray-700 p-2"
                  >
                    <font-awesome-icon icon="fa-solid fa-chevron-up" />
                  </button>
                  <button
                    v-if="index !== parentCategories.length - 1"
                    class="bg-gray-500 hover:bg-gray-700 p-2"
                  >
                    <font-awesome-icon icon="fa-solid fa-chevron-down" />
                  </button> -->
                  <!-- <button class="bg-gray-500 hover:bg-gray-700 p-2">
                        <font-awesome-icon
                          icon="fa-solid fa-up-down-left-right"
                        />
                      </button> -->
                  <button
                    @click="editMenu(navbar, navbar.cid)"
                    class="bg-sky-500 hover:bg-sky-700 p-2"
                  >
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                  </button>
                  <button
                    @click="deleteMenu(navbar.category, navbar.cid)"
                    class="bg-red-500 hover:bg-red-700 p-2"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash" />
                  </button>
                </div>
              </div>

              <!-- Child Menu -->
              <div v-if="currentOpenChildMenu === navbar.cid" class="my-1">
                <div
                  v-for="(child, childIndex) in navbar.children"
                  :key="childIndex"
                  class="flex flex-col ml-7"
                >
                  <div class="flex items-center justify-between uppercase">
                    <h1 class="text-wrap">{{ child.category }}</h1>
                    <div class="text-white text-xs">
                      <button
                        @click="editMenu(child, navbar.cid)"
                        class="bg-sky-500 hover:bg-sky-700 p-2"
                      >
                        <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                      </button>
                      <button
                        @click="deleteMenu(child.category, child.cid)"
                        class="bg-red-500 hover:bg-red-700 p-2"
                      >
                        <font-awesome-icon icon="fa-solid fa-trash" />
                      </button>
                    </div>
                  </div>

                  <!-- Grandchild Menu -->
                  <div class="my-1">
                    <div
                      v-for="(
                        grandchild, grandchildIndex
                      ) in child.grandchildren"
                      :key="grandchildIndex"
                      class="flex items-center justify-between uppercase ml-5"
                    >
                      <h1 class="text-wrap">{{ grandchild.category }}</h1>
                      <div class="text-white text-xs">
                        <button
                          @click="editMenu(grandchild, navbar.cid)"
                          class="bg-sky-500 hover:bg-sky-700 p-2"
                        >
                          <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                        </button>
                        <button
                          @click="
                            deleteMenu(grandchild.category, grandchild.cid)
                          "
                          class="bg-red-500 hover:bg-red-700 p-2"
                        >
                          <font-awesome-icon icon="fa-solid fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!-- Add url -->
        <div class="rounded shadow w-[25rem] h-fit">
          <h6 class="border-b p-3">Нэмэх / Засварлах</h6>

          <div class="flex flex-col gap-2 p-3 w-full">
            <h6 v-if="!form.cid" class="font-bold">Хуудасны төрөл</h6>
            <div v-if="!form.cid" class="flex items-center">
              <input
                type="checkbox"
                class="mr-1"
                :checked="form.page_type === 'news'"
                @change="selectPageType('news')"
              />
              <span class="mr-5">Мэдээний хуудас</span>
              <input
                type="checkbox"
                class="mr-1"
                :checked="form.page_type === 'static'"
                @change="selectPageType('static')"
              />
              <span class="mr-5">Статик хуудас</span>
            </div>

            <div v-if="form.page_type" class="flex flex-col gap-2">
              <h6 v-if="!form.cid" class="font-bold">Цэсний төрөл</h6>
              <div v-if="!form.cid" class="flex items-center">
                <input
                  type="checkbox"
                  class="mr-1"
                  :checked="form.category_level === 1"
                  @change="selectMenu(1)"
                />
                <span class="mr-5">Үндсэн цэс</span>

                <input
                  type="checkbox"
                  class="mr-1"
                  :checked="form.category_level === 2"
                  @change="selectMenu(2)"
                />
                <span class="mr-5">Дэд цэс</span>

                <input
                  v-if="form.page_type === 'static'"
                  type="checkbox"
                  class="mr-1"
                  :checked="form.category_level === 3"
                  @change="selectMenu(3)"
                />
                <span v-if="form.page_type === 'static'">Гуравдагч цэс</span>
              </div>

              <label
                v-if="form.category_level === 2 || form.category_level === 3"
                for="form.category_level"
                class="font-bold"
                >{{
                  form.category_level === 2
                    ? "Холбогдох үндсэн цэс"
                    : "Холбогдох дэд цэс"
                }}</label
              >
              <select
                v-if="form.category_level === 2 || form.category_level === 3"
                name="form.category_level"
                id="form.category_level"
                class="border rounded p-2"
                v-model="form.pid"
              >
                <option value="" disabled selected>сонгох</option>
                <option
                  v-for="(item, index) in categorySelections"
                  :key="index"
                  :value="item.cid"
                >
                  {{ item.category }}
                </option>
              </select>

              <label for="category" class="font-bold">Цэсний нэр</label>
              <input
                type="text"
                name="category"
                id="category"
                placeholder="Ангилал"
                class="border rounded p-2"
                v-model="form.category"
              />

              <label for="category_en" class="font-bold"
                >Цэсний нэр /англи/</label
              >
              <input
                type="text"
                name="category_en"
                id="category_en"
                placeholder="Category"
                class="border rounded p-2"
                v-model="form.category_en"
              />

              <label for="category_status" class="font-bold"
                >Цэсний төлөв</label
              >
              <select
                name="category_status"
                id="category_status"
                class="border rounded p-2"
                v-model="form.category_status"
              >
                <option value="true">Идэвхитэй</option>
                <option value="false">Идэвхигүй</option>
              </select>
            </div>
          </div>

          <div class="flex bg-gray-200 gap-5 p-3 text-xs text-white">
            <button
              @click="uploadMenu()"
              class="p-2"
              :class="
                form.cid
                  ? 'bg-sky-500 hover:bg-sky-600'
                  : 'bg-[#28a745] hover:bg-[#208537]'
              "
            >
              <font-awesome-icon
                :icon="form.cid ? 'fa-solid fa-pen' : 'fa-solid fa-add'"
                class="mr-1"
              />{{ form.cid ? "Линк засварлах" : "Линк нэмэх" }}
            </button>

            <button
              @click="resetForm()"
              class="p-2 bg-orange-400 hover:bg-orange-500"
            >
              <font-awesome-icon
                icon="fa-solid fa-eraser"
                class="mr-1"
              />Арилгах
            </button>
          </div>
        </div>
      </div>

      <div
        v-else-if="categories.length === 0 && !isLoading"
        class="flex justify-center items-center h-[60vh]"
      >
        Цэс хоосон байна
      </div>

      <div v-else class="flex justify-center items-center h-[60vh]">
        <SpinLoading :color="'rgb(59 130 246)'" />
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "MMWebUrl",
  components: {
    AdminNavbar,
    SpinLoading,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const categories = computed(() => store.getters["admin/getCategory"]);
    const parentCategories = ref([]);

    const fetchAllCategories = async () => {
      try {
        await store.dispatch("admin/fetchAllCategories");
        parentCategories.value = categories.value
          .filter((item) => item.pid === null)
          .map((parent) => {
            parent.children = categories.value
              .filter((child) => child.pid === parent.cid)
              .map((child) => {
                child.category_level = 2;
                child.grandchildren = categories.value
                  .filter((grandchild) => grandchild.pid === child.cid)
                  .map((grandchild) => {
                    grandchild.category_level = 3;
                    return grandchild;
                  });
                return child;
              });
            parent.category_level = 1;
            return parent;
          });
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchAllCategories();
    });

    const currentOpenChildMenu = ref(null);
    const toggleChildMenu = (cid) => {
      if (currentOpenChildMenu.value === cid) {
        currentOpenChildMenu.value = null;
      } else {
        currentOpenChildMenu.value = cid;
      }
    };

    //Нэмэх, засах, шинэчлэх
    const form = ref({
      cid: null,
      category: null,
      category_en: null,
      pid: null,
      category_status: true,
      page_type: null,
      category_level: null,
    });

    const resetForm = () => {
      form.value = {
        cid: null,
        category: null,
        category_en: null,
        pid: null,
        category_status: true,
        page_type: null,
        category_level: null,
      };
    };

    const selectPageType = (index) => {
      if (form.value.page_type === index) {
        form.value.page_type = null;
      } else {
        form.value.page_type = index;
      }
    };

    const selectMenu = (index) => {
      if (form.value.category_level === index) {
        form.value.category_level = null;
      } else {
        form.value.category_level = index;
      }
    };
    const categorySelections = ref([]);

    const selectedCategories = () => {
      try {
        const filteredParentCategories = parentCategories.value.filter(
          (parent) => {
            return parent.page_type === form.value.page_type;
          }
        );

        if (form.value.category_level === 2) {
          categorySelections.value = filteredParentCategories;
        } else if (form.value.category_level === 3) {
          let children = [];
          filteredParentCategories.forEach((parent) => {
            if (parent.children) {
              children = children.concat(parent.children);
            }
          });
          categorySelections.value = children;
        } else {
          categorySelections.value = [];
        }
      } catch (err) {
        console.error("Error filtering selected categories:", err);
      }
    };

    watch(
      [() => form.value.page_type, () => form.value.category_level],
      async (
        [newPageType, newCategoryLevel],
        [oldPageType, oldCategoryLevel]
      ) => {
        if (
          newPageType !== oldPageType ||
          newCategoryLevel !== oldCategoryLevel
        ) {
          selectedCategories();
        }
      }
    );

    const editMenu = (menu, parentCid) => {
      const parentCategory = categories.value.find(
        (parent) => parent.cid === parentCid
      );

      if (parentCategory) {
        form.value.page_type = parentCategory.page_type;
      }
      form.value = { ...menu, page_type: form.value.page_type };
    };

    const deleteMenu = async (name, cid) => {
      if (!window.confirm(`'${name}' цэсийг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(`/delete/category?cid=${cid}`);
        if (res.status === 200) window.location.reload();
      } catch (err) {
        return err;
      }
    };

    const isValid = () => {
      if (!form.value.cid) {
        if (!form.value.page_type) return alert("Хуудасны төрлийг сонгоно уу");
        if (!form.value.category_level)
          return alert("Цэсний төрлийг сонгоно уу");
        if (form.value.category_level !== 1) {
          if (!form.value.pid) return alert("Холбогдох цэсийг сонгоно уу");
        }
      }

      if (!form.value.category) return alert("Цэсний нэрийг оруулна уу");
      if (!form.value.category_en)
        return alert("Цэсний англи нэрийг оруулна уу");

      return true;
    };

    const uploadMenu = async () => {
      if (!isValid()) return;

      if (form.value.category_level !== 1) {
        form.value.page_type = null;
      }

      try {
        if (!form.value.cid) {
          const res = await adminInstance.post("/insert/category", form.value);
          if (res.status === 200) window.location.reload();
        } else {
          const res = await adminInstance.post("/update/category", form.value);
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    return {
      parentCategories,
      currentOpenChildMenu,
      toggleChildMenu,
      form,
      editMenu,
      isLoading,
      categories,
      selectMenu,
      selectPageType,
      uploadMenu,
      resetForm,
      categorySelections,
      deleteMenu,
    };
  },
};
</script>
