<template>
  <main class="p-5">
    <button
      @click="closeUpdateWindow"
      class="bg-blue-500 p-2 text-white rounded"
    >
      <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" /> Буцах
    </button>

    <section class="mt-5">
      <h2 class="text-lg font-bold">Холбоо барих</h2>
      <div v-if="company?.contact" class="grid gap-4">
        <div
          v-for="(contact, index) in company.contact"
          :key="index"
          class="flex gap-2"
        >
          <input
            v-model="contact.contact_type"
            class="border p-2 w-1/4"
            readonly
          />
          <input
            v-model="contact.contact_value"
            class="border p-2 w-3/4"
            placeholder="Мэдээлэл оруулна уу"
          />
          <button
            @click="deleteContact(contact.id, index)"
            class="bg-red-500 text-white text-center px-3 py-1 rounded"
          >
            <font-awesome-icon icon="fa-solid fa-trash" />
          </button>
        </div>
      </div>

      <div v-if="newContact" class="grid gap-4 mt-4">
        <div
          v-for="(contact, index) in newContact"
          :key="index"
          class="flex gap-2 items-center"
        >
          <select v-model="contact.contact_type" class="border p-2 w-1/4">
            <option value="" disabled>Сонгох</option>
            <option value="phone">phone</option>
            <option value="fax">fax</option>
            <option value="email">email</option>
            <option value="website">website</option>
          </select>
          <input
            v-model="contact.contact_value"
            class="border p-2 w-3/4"
            placeholder="Мэдээлэл оруулна уу"
          />
          <button
            @click="removeContactField(index)"
            class="bg-red-500 text-white px-3 py-2 rounded"
          >
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
        </div>
      </div>

      <button
        @click="addContactField"
        class="bg-blue-400 text-white p-2 rounded mt-3 hover:bg-blue-500"
      >
        + Холбоо барих нэмэх
      </button>

      <h2 class="text-lg font-bold mt-5">Компанийн мэдээлэл</h2>
      <div v-if="company?.about" class="grid gap-4">
        <div
          v-for="(about, index) in company.about"
          :key="index"
          class="flex flex-col gap-2"
        >
          <label class="font-semibold">Нэр ({{ about.lang }})</label>
          <input
            v-model="about.name"
            class="border p-2"
            placeholder="Компанийн нэр"
          />

          <label class="font-semibold">Байршил</label>
          <input
            v-model="about.location"
            class="border p-2"
            placeholder="Байршил"
          />

          <label class="font-semibold">Хаяг</label>
          <input
            v-model="about.address"
            class="border p-2"
            placeholder="Хаяг"
          />

          <label class="font-semibold">Компанийн тухай</label>
          <textarea
            v-model="about.about_company"
            class="border p-2"
            placeholder="Компанийн дэлгэрэнгүй мэдээлэл"
          ></textarea>
        </div>

        <label class="font-semibold mt-3">Лого (зураг оруулах)</label>
        <input
          type="file"
          @change="handleFileUpload"
          class="border p-2"
          accept="image/*"
        />

        <div v-if="sharedLogo" class="mt-2">
          <p class="text-sm text-gray-600">Одоогийн лого:</p>
          <img
            :src="sharedLogo"
            alt="Company Logo"
            class="h-20 object-contain border"
          />
        </div>
      </div>

      <div class="flex justify-center">
        <button
          @click="updateCompany"
          class="bg-green text-white p-2 rounded mt-5"
        >
          Хадгалах
        </button>
      </div>
    </section>

    <SuccessModal v-if="success" :success="success" />
    <ErrorModal v-if="error" :error="error" />
  </main>
</template>

<script>
import { ref, watch, computed } from "vue";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "AdminUpdateCompany",
  props: {
    companyId: Number,
  },
  components: {
    SuccessModal,
    ErrorModal,
  },
  emits: ["showList"],
  setup(props, { emit }) {
    const closeUpdateWindow = () => {
      emit("showList");
    };

    const company = ref(null);
    const success = ref(null);
    const error = ref(null);

    const fetchCompanyInformation = async () => {
      try {
        const res = await adminInstance.get(
          `/yellow-page/get/company-detail?id=${props.companyId || 1}`
        );
        if (res.status === 200 && res.data.company) {
          company.value = res.data.company;
        }
      } catch (err) {
        error.value = "Байгууллагын мэдээллийг татахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    watch(
      () => props.companyId,
      (newVal) => {
        if (newVal) {
          fetchCompanyInformation();
        }
      },
      { immediate: true }
    );

    const sharedLogo = computed({
      get: () => company.value?.about?.[0]?.logo || "",
      set: (newVal) => updateSharedValue("logo", newVal),
    });

    const updateSharedValue = (field, value) => {
      if (company.value?.about) {
        company.value.about.forEach((about) => {
          about[field] = value;
        });
      }
    };

    const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("image", file);

      try {
        const res = await adminInstance.post(
          "/yellow-page/upload/logo",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (res.status === 200 && res.data.fileUrl) {
          updateSharedValue("logo", res.data.fileUrl);
        }
      } catch (err) {
        error.value = "Зураг байршуулахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const newContact = ref([]);
    const addContactField = () => {
      newContact.value.push({ contact_type: "", contact_value: "" });
    };
    const removeContactField = (index) => {
      newContact.value.splice(index, 1);
    };

    const deleteContact = async (contactId, index) => {
      if (!window.confirm(`Мэдээллийг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(
          `/yellow-page/delete/contact?id=${contactId}`
        );
        if (res.status === 200) {
          company.value.contact.splice(index, 1);
        }
      } catch (err) {
        error.value = "Холбоо барих мэдээллийг устгахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const updateCompany = async () => {
      try {
        const res = await adminInstance.post(
          "/yellow-page/update/company-detail",
          { company: company.value, new_contact: newContact.value }
        );
        if (res.status === 200) {
          success.value = "Амжилттай шинэчлэгдлээ.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Мэдээллийг хадгалахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    return {
      closeUpdateWindow,
      company,
      success,
      error,
      updateCompany,
      sharedLogo,
      updateSharedValue,
      handleFileUpload,
      addContactField,
      removeContactField,
      newContact,
      deleteContact,
    };
  },
};
</script>
